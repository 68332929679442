import React from "react";
import styled from "styled-components";

import { theme } from "../../theme/theme";
import {
  ContentfulTable,
  ContentfulTableRow,
  ContentfulTableCell,
} from "../../utils/contentful";
import { ConditionalWrapper } from "./conditional-wrapper";
import { HalfCenteredRow } from "./layout";

const doubleHalfMediaQuery: string = "@media screen and (min-width: 68.75em)"; //110px

export const TableWrapper = styled.div`
  overflow: auto;

  &:not(:first-child) {
    margin-top: 2em;
  }

  ${doubleHalfMediaQuery} {
    ${HalfCenteredRow} & {
      width: 200%;
      margin-left: -50%;
    }
  }
`;

const TableCell = styled.td`
  padding: 1em;
  text-align: left;
`;

const StyledTable = styled.table`
  table-layout: fixed;
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
  border-spacing: 0;

  &,
  ${TableCell} {
    border: 2px solid ${theme.palette.common.grey};
  }

  &:not(:first-child) {
    margin-top: 2em;
  }
`;

type PropsModel = {
  data: ContentfulTable;
};

function generateRows(tr: ContentfulTableRow[], keyPrefix: string) {
  return tr.map((row: ContentfulTableRow, i: number) => {
    return (
      <tr key={keyPrefix + i}>
        {row.tr.map((cell: ContentfulTableCell, j: number) => {
          if (cell.nodeType === "th")
            return (
              <TableCell
                key={keyPrefix + i + " " + j}
                className="text text--bold"
                rowSpan={cell.rowspan}
                dangerouslySetInnerHTML={{
                  __html: cell.body,
                }}
                as="th"
              />
            );
          else
            return (
              <TableCell
                key={keyPrefix + i + " " + j}
                className="text"
                dangerouslySetInnerHTML={{
                  __html: cell.body,
                }}
              ></TableCell>
            );
        })}
      </tr>
    );
  });
}

export const Table = ({ data }: PropsModel) => {
  return (
    <ConditionalWrapper
      condition={data.childContentfulTableContentJsonNode.wide}
      wrapper={(children) => <TableWrapper>{children}</TableWrapper>}
    >
      <StyledTable>
        {data.childContentfulTableContentJsonNode.thead.length ? (
          <thead>
            {generateRows(
              data.childContentfulTableContentJsonNode.thead,
              data.contentful_id + "_thead_"
            )}
          </thead>
        ) : (
          ""
        )}
        {data.childContentfulTableContentJsonNode.tbody.length ? (
          <tbody>
            {generateRows(
              data.childContentfulTableContentJsonNode.tbody,
              data.contentful_id + "_tbody_"
            )}
          </tbody>
        ) : (
          ""
        )}
      </StyledTable>
    </ConditionalWrapper>
  );
};
