export const getPageTitle = (title: string) => `${title} | Joko`;

export interface LocaleTexts {
  language: string;
  home: {
    pageTitle: string;
    downloadButtons: {
      appStore: string;
      browserExtension: string;
    };
    footer: {
      links: {
        legals: string;
        tos: string;
        privacyPolicy: string;
        blog: string;
        jobs: string;
        help: string;
        press: string;
        webApp: string;
      };
    };
    outdatedBrowserAlert: {
      title: string;
      subtitle: string;
    };
    introImageAltText: string;
    presentationImagesAltText: string;
  };
  shopOnDesktop: {
    pageTitle: string;
    adBlockerDetected: {
      title: string;
      subtitle: string;
    };
    redirection: {
      title: string;
    };
  };
  oauth2: {
    pageTitle: string;
    success: {
      title: string;
      subtitle: string;
    };
    error: {
      title: string;
    };
  };
  emailConfirmation: {
    pageTitle: string;
    title: string;
    subtitle: string;
  };
  pageNotFound: {
    pageTitle: string;
    text: string;
    goBackLink: string;
  };
  uninstallationForm: {
    pageTitle: string;
    form: {
      preTitle: string;
      title: string;
      indication: string;
      otherReason: string;
      otherReasonPlaceholder: string;
      submitButtonLabel: string;
      formCheckboxValues: {
        extensionAppearsTooOften: string;
        notEnoughBrands: string;
        purchasesNotTakenIntoAccount: string;
        extensionBugs: string;
        noPurchaseOnThisComputer: string;
        notEnoughCashback: string;
        cashbackNotFastEnough: string;
        useDifferentCashbackExtension: string;
        doNotUnderstandTheIdeaOfCashback: string;
        privacyReasons: string;
      };
    };
    thanks: {
      title: string;
      indication: string;
    };
  };
  link: {
    openAppButton: string;
    goBackButton: string;
  };
}
