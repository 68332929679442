import * as React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";

import { ContentfulTable } from "../utils/contentful";
import { Table } from "../components/common/table";

export function formatBodyRaw(raw: any, tableNodes?: ContentfulTable[]) {
  const bodyOptions = {
    renderMark: {
      [MARKS.BOLD]: (text) => <b>{text}</b>,
      [MARKS.ITALIC]: (text) => <i>{text}</i>,
    },
    renderNode: {
      [INLINES.HYPERLINK]: ({ data }, children) => {
        return (
          <a className="link" href={data.uri}>
            {children}
          </a>
        );
      },
      [BLOCKS.PARAGRAPH]: (_node, children) => {
        if (
          tableNodes &&
          (children.includes("ContentfulTable") ||
            children.filter(
              (child: string) => child.toString().indexOf("ContentfulTable") > 0
            ).length)
        ) {
          const node = tableNodes.filter(
            (node) =>
              `[ContentfulTable:"${node.contentful_id}"]` === children[0] ||
              children.includes(node.contentful_id)
          );

          if (node.length) {
            return <Table data={node[0]} />;
          } else return "";
        } else return <p className="text">{children}</p>;
      },
      [BLOCKS.UL_LIST]: (_node, children) => {
        return <ul className="ul-list">{children}</ul>;
      },
      [BLOCKS.LIST_ITEM]: (_node, children) => {
        return <li className="text">{children}</li>;
      },
      [BLOCKS.HEADING_2]: (_node, children) => {
        return <h2 className="small-heading">{children}</h2>;
      },
      [BLOCKS.HEADING_3]: (_node, children) => {
        return <h3 className="small-heading">{children}</h3>;
      },
      [BLOCKS.HEADING_4]: (_node, children) => {
        return <h4 className="emphasized-text">{children}</h4>;
      },
      [BLOCKS.HEADING_5]: (_node, children) => {
        return <h5 className="text text--bold">{children}</h5>;
      },
    },
  };

  return raw ? documentToReactComponents(JSON.parse(raw), bodyOptions) : "";
}
